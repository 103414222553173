import { expiredDiscountKey, useGlobalState } from '@libs/client'
import { useEventListener, usePromotionsSellpage } from '@ui/hooks'
import { useMemo, useRef } from 'react'
import css from 'styled-jsx/css'

const ExpiredDiscount = () => {
  const [promotionsSellpage] = usePromotionsSellpage()
  const [, setIsExpiredDiscount] = useGlobalState<boolean>(expiredDiscountKey)

  const firstMounted = useRef(0)
  useEventListener({
    type: 'scroll',
    listener: () => {
      if (!!firstMounted.current && firstMounted.current !== window.pageYOffset) {
        setIsExpiredDiscount(false)
      }
      firstMounted.current = window.pageYOffset
    },
    options: { passive: true },
    element: typeof document !== 'undefined' ? document : undefined
  })

  const textDiscount = useMemo(() => {
    if (promotionsSellpage) {
      const promotiton = Object.values(promotionsSellpage)?.[0] as S3Types.promotions
      return promotiton?.type === 'PERCENT'
        ? `EXTRA ${promotiton?.value || 0}% OFF TODAY`
        : 'FREE SHIPPING TODAY'
    }
  }, [promotionsSellpage])
  return (
    <div className="ex-dsc">
      <style jsx global>
        {globalStyle}
      </style>
      {promotionsSellpage ? (
        <div className="ex-dsc__has-dsc-sellpage">
          <p className="ex-dsc__has-dsc-sellpage--expired">The discount code is expired!</p>
          <p className="ex-dsc__has-dsc-sellpage--user">
            No worries, we have another deal for you!
          </p>
          <div className="ex-dsc__has-dsc-sellpage--value">{textDiscount}</div>
        </div>
      ) : (
        <div className="ex-dsc__no-dsc-sellpage">
          The discount code is expired...But this item is running out of stock. Get it now!
        </div>
      )}
    </div>
  )
}

const globalStyle = css.global`
  .ex-dsc {
    position: fixed;
    top: 0;
    z-index: 100;
    background: #ffffff;
    width: 100%;

    &__has-dsc-sellpage {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      background: #e8f5e9;
      padding: 10px 16px 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--expired {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        padding-bottom: 2px;
      }

      &--user {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        padding-bottom: 8px;
      }
      &--value {
        background: #ffffff;
        border: 2px dashed #388e3c;
        border-radius: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #388e3c;
        width: 100%;
        padding: 6px 16px;

        @media screen and (min-width: 768px) {
          max-width: 342px;
          margin: auto;
        }
      }
    }

    &__no-dsc-sellpage {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      background: #fff3e0;
      padding: 10px 16px 14px;
      text-align: center;
    }
  }
`

export default ExpiredDiscount
