import { FC, HTMLAttributes, useEffect, useState } from 'react'
import css from 'styled-jsx/css'
import useToggle from '@ui/hooks/useToggle'
import Logo from '@ui/components/shared/Logo'
import Menu from '@ui/components/shared/Menu'
import { iconPath } from '@ui/helpers'
import { useSetting } from '@ui/hooks'
import { store_type } from '@libs/common/models/constant'
import { useRouter } from 'next/router'
import SaleAnnouncement from './SaleAnnouncement'
import AnnouncementBar from './AnnouncementBar'
import { expiredDiscountKey, useGlobalState } from '@libs/client'
import ExpiredDiscount from './ExpiredDiscount'

interface IHeader extends HTMLAttributes<HTMLDivElement> {
  haveSaleBar?: boolean
  redirectable?: boolean
}

const Header: FC<IHeader> = ({ haveSaleBar = false, redirectable = true, ...props }) => {
  const router = useRouter()
  const slug = router.query?.subpath?.toString()
  const [storeSetting] = useSetting('store')
  const [sellpageSetting] = useSetting('sellpage')
  const isMultiStore = storeSetting?.general?.type === store_type.MINISTORE
  const hasMenu = (isMultiStore && !slug) || sellpageSetting?.settings?.configurations?.has_menu
  const [active, { toggle }] = useToggle(false)
  const [isExpiredDiscount] = useGlobalState<boolean>(expiredDiscountKey)

  const [showSale, setShowSale] = useState(false)

  useEffect(() => {
    toggle(false)
  }, [router])

  return (
    <>
      {!showSale &&
        !router.query?.p?.toString() &&
        !sellpageSetting?.settings?.configurations?.hide_clearance && (
          <AnnouncementBar text={sellpageSetting?.parts?.anouncement_bar?.content} />
        )}

      <div
        {...props}
        className={`header--primary ${isExpiredDiscount ? 'is-expired-discount' : ''} ${
          showSale ? 'show-sale' : ''
        } ${props.className ?? ''}`}
      >
        <style jsx global>
          {globalStyle}
        </style>
        {haveSaleBar &&
          (!isExpiredDiscount ? (
            <SaleAnnouncement
              onShowChanged={(show) => {
                setShowSale(show)
              }}
            />
          ) : (
            <ExpiredDiscount />
          ))}
        <nav className="navbar is-light is-transparent has-centered-logo-alt">
          <div className="navbar-brand is-hidden-desktop">
            <Logo className="navbar-item" redirectable={redirectable} />
            {hasMenu && (
              <a className="navbar-burger burger" onClick={() => toggle()}>
                <figure className="menu-image image">
                  <img src={iconPath('menu.svg')} alt="" />
                </figure>
              </a>
            )}
          </div>
          <div className="navbar-menu">
            <div className="navbar-start">
              {hasMenu && (
                <a className="navbar-item" onClick={() => toggle()}>
                  <figure className="menu-image image">
                    <img src={iconPath('menu.svg')} alt="" />
                  </figure>
                </a>
              )}
            </div>
            <div className="navbar-brand is-hidden-touch">
              {!showSale && <Logo className="navbar-item" redirectable={redirectable} />}
              {hasMenu && (
                <a className="navbar-burger burger" onClick={() => toggle()}>
                  <figure className="menu-image image">
                    <img src={iconPath('menu.svg')} alt="" />
                  </figure>
                </a>
              )}
            </div>
            <div className="navbar-end" />
          </div>
        </nav>
        {showSale && (
          <nav className="navbar is-light is-transparent has-centered-logo-alt is-hidden-touch">
            <div className="navbar-menu">
              <div className="navbar-start"></div>
              <div className="navbar-brand is-hidden-touch">
                <Logo className="navbar-item" redirectable={redirectable} />
              </div>
              <div className="navbar-end"></div>
            </div>
          </nav>
        )}
        {hasMenu && <Menu active={active} toggleModal={toggle} />}
      </div>
    </>
  )
}

const globalStyle = css.global`
  .header--primary {
    .top-0 {
      top: 0;
    }

    @media screen and (max-width: 768px) {
      padding-top: 0px;
      padding-bottom: 0px;

      &.show-sale {
        padding-top: 0;

        .navbar {
          padding-top: 10px;
        }
      }
    }

    @media screen and (min-width: 769px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @media screen and (min-width: 1024px) {
      .announcement-sale-bar {
        position: absolute;
        right: 0;
        left: 0;
        z-index: 40;
      }
    }

    .navbar {
      background-color: var(--background);
      //background-color: #f4f6f8;
      width: 100%;

      .navbar-brand {
        align-items: center;
      }

      .menu-image > img {
        height: 18px;
        width: 21px;
      }

      .navbar-item {
        &:hover {
          background-color: transparent !important;
        }

        &.logo {
          img {
            width: 210px;
            max-height: 120px;

            @media screen and (min-width: 1024px) {
              object-position: center;
            }
          }
        }
      }

      .navbar-burger {
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: transparent !important;
        }

        @media screen and (min-width: 1024px) {
          display: none;
        }
      }

      @media screen and (min-width: 1216px) {
        .navbar-start {
          padding-left: 40px;
        }

        .navbar-end {
          padding-right: 40px;
        }
      }

      &.has-centered-logo-alt {
        .navbar-start,
        .navbar-end {
          flex: 1 1 0;
        }
      }
    }

    &.is-expired-discount {
      padding: 0;
      background: #ffffff;
    }
  }
`

export default Header
