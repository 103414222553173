import React, { FC, useEffect } from 'react'
import css from 'styled-jsx/css'

import { freeze, iconPath, unFreeze } from '@ui/helpers'
import SfLink from '../next/SfLink'
import { useIsPreview } from '@ui/hooks'
import { useGlobalState } from '@libs/client'

interface MenuProps extends React.AnchorHTMLAttributes<HTMLDivElement> {
  active: boolean
  toggleModal: () => void
}

const Menu: FC<MenuProps> = ({ active = false, toggleModal }) => {
  const [colections] = useGlobalState<S3Types.collection | null>('store.collection')

  const isPreview = useIsPreview()
  useEffect(() => {
    if (active) {
      freeze()
    } else {
      unFreeze()
    }

    return () => {
      if (active) {
        unFreeze()
      }
    }
  }, [active])

  return (
    <div
      className={`contact-form-menu-modal modal slide-left-desktop slide-right-mobile${
        active ? ' is-active' : ''
      } `}
      style={{
        visibility: active ? 'visible' : 'hidden'
      }}
    >
      <style jsx global>
        {globalStyle}
      </style>
      <div className="modal-background" onClick={() => toggleModal()} />
      <div className="modal-card">
        <section className="modal-card-body">
          <a className="close" onClick={() => toggleModal()}>
            <figure className="image">
              <img src={iconPath('delete.svg')} alt="" />
            </figure>
          </a>
          <div className="menu">
            <>
              <SfLink className="menu-item" pathName="/">
                All products
              </SfLink>
              {Object.values(colections?.['new-arrivals'] || {}).length ? (
                <SfLink className="menu-item" pathName="/c/new-arrivals">
                  New Arrivals
                </SfLink>
              ) : (
                ''
              )}
              {Object.values(colections?.['bestsellers'] || {}).length ? (
                <SfLink className="menu-item" pathName="/c/bestsellers">
                  Bestsellers
                </SfLink>
              ) : (
                ''
              )}
              {Object.values(colections?.['pre-order'] || {})?.length ? (
                <SfLink className="menu-item" pathName="/c/pre-order">
                  Pre Order
                </SfLink>
              ) : (
                ''
              )}
              {Object.values(colections?.['clearance'] || {}).length ? (
                <SfLink className="menu-item" pathName="/c/clearance">
                  Clearance<div className="menu-item__badge">SALE OFF</div>
                </SfLink>
              ) : (
                ''
              )}
            </>

            {!isPreview && <SfLink pathName="/trackings/search">Order Tracking</SfLink>}
            {!isPreview && (
              <SfLink pathName="/contact" className="contact-link">
                Contact us
              </SfLink>
            )}
          </div>
          <div className="support">
            <hr className="menu__divider" />
            <SfLink pathName="/static/exchanges-returns">Exchanges & Returns</SfLink>
            {/* <SfLink pathName="/static/payment-methods">Payment Methods</SfLink> */}
            <SfLink pathName="/static/orders-shipping">Order & Shipping</SfLink>
            <SfLink pathName="/static/terms-of-service">Terms of Service</SfLink>
            <SfLink pathName="/static/privacy">Privacy Policy</SfLink>
          </div>
        </section>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .contact-form-menu-modal {
    .modal-card {
      height: 100vh;
      max-height: 100vh;
      max-width: 280px;
      margin-left: 0;

      @media screen and (max-width: 768px) {
        margin-right: 0;
        max-width: 100vw;
        width: 100vw;
      }

      .modal-card-body {
        background-color: #1b1b1b;
        padding: 40px 40px 10px;
        display: flex;
        flex-direction: column;
      }
    }

    .close {
      display: inline-block;
      margin-bottom: 22px;

      img {
        width: 12px;
        height: 12px;
      }
    }

    a {
      color: white;
      display: block;
      // padding: 15px 0;
      font-weight: normal;
      font-size: 15px;
      line-height: 32px;
      margin-bottom: 32px;

      &:hover {
        text-decoration: underline;
      }
    }

    .contact-link {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      padding: 8px 32px;
      width: fit-content;
      height: auto;
      color: #fff;
      background-color: #2d9cdb;
      border-color: transparent;
      border-radius: 4px;

      &:hover {
        color: #fff;
      }
    }

    .support {
      color: white;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 32px;
      margin-bottom: 30px;

      a {
        color: #828282;
        font-size: 13px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .menu {
      &__divider {
        background-color: #333;
        margin-top: 0;
        margin-bottom: 16px;
      }

      a:last-child {
        margin-bottom: 0;
      }

      &-item {
        &__badge {
          display: inline-block;
          color: #ffffff;
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.5px;

          padding: 0px 8px;
          border-radius: 4px;
          background: #ef5350;
          margin-left: 8px;
        }
      }
    }

    .support {
      a {
        color: #828282;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .menu {
        &-item {
          &__badge {
            font-size: 14px;
          }
        }
      }
    }
  }
`

export default Menu
