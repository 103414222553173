import { useLocalDiscountCode } from '@libs/client'
import { promotion_type } from '@libs/common/models/constant'
import { getTimeArr } from '@ui/helpers'
import { usePromotions, useSetting } from '@ui/hooks'
import { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'
import css from 'styled-jsx/css'

interface ISaleAnnoucement extends HTMLAttributes<HTMLDivElement> {
  onShowChanged?: (show: boolean) => void
}

const SaleAnnouncement: FC<ISaleAnnoucement> = ({ onShowChanged, ...props }) => {
  const [storeSetting] = useSetting('store')
  const [sellpageSetting] = useSetting('sellpage')
  const promotions = usePromotions()
  const [discountCode] = useLocalDiscountCode()

  const [saleContent, setSaleContent] = useState('')
  const [isHide, setIsHide] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const timeRemainSync = useRef(0)
  const [listTime, setListTime] = useState<any[]>([])
  const domain = useMemo(
    () => (storeSetting?.general?.name || storeSetting?.general?.domain?.split('.')?.[0]) ?? '',
    [storeSetting]
  )
  const countdownInterval = useRef<NodeJS.Timeout | null>()

  const haveData = useMemo(() => saleContent.length > 0 && listTime.length > 0, [
    saleContent,
    listTime
  ])
  const countdownTime = useMemo(
    () => sellpageSetting?.settings?.social_proof?.countdown_time || 788,
    [sellpageSetting]
  )

  const initCountDown = () => {
    if (!timeRemainSync.current) {
      timeRemainSync.current = countdownTime ?? 0
    }
    countdownInterval.current = setInterval(() => {
      if (timeRemainSync.current > 0) {
        timeRemainSync.current--

        setListTime(getTimeArr(timeRemainSync.current))
      } else {
        countdownInterval.current && clearInterval(countdownInterval.current)
        toggleSaleAnnouncement(false)
      }
    }, 1000)
  }

  const toggleSaleAnnouncement = (show: boolean) => {
    setIsHide(!show)
    onShowChanged?.(show)
  }

  useEffect(() => {
    initCountDown()

    const announcementBar = document.getElementsByClassName('announcement-sale-bar__hook')
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        setIsSticky(!entry.isIntersecting)
      })
    })
    if (announcementBar[0]) {
      observer.observe(announcementBar[0])
    }

    return () => {
      countdownInterval.current && clearInterval(countdownInterval.current)
      observer?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (discountCode) {
      const promotion = promotions?.[discountCode]
      let content = ''
      if (promotion?.type === promotion_type.SHIP) {
        content = '<span style="font-size: 20px">Free Shipping</span>'
      } else if (promotion?.type === promotion_type.PERCENT && promotion?.value) {
        content = `EXTRA ${promotion.value}% OFF<span class="announcement__sale-content--checkout">at checkout</span>`
      } else if (promotion?.type === promotion_type.FIXED && promotion?.value) {
        content = `EXTRA $${promotion.value} OFF<span class="announcement__sale-content--checkout">at checkout</span>`
      }
      if (content) {
        setSaleContent(content)
      } else {
        toggleSaleAnnouncement(false)
      }
    }
  }, [discountCode])

  useEffect(() => {
    onShowChanged?.(haveData)
  }, [haveData])

  return (
    <div
      {...props}
      className={`announcement-sale-bar ${props.className ?? ''} ${
        !isHide && haveData ? '' : 'announcement-sale-bar--hide'
      }`}
    >
      <style jsx global>
        {globalStyle}
      </style>
      <div className="announcement-sale-bar__hook"></div>
      <div
        className={`announcement-sale-bar__content ${
          isSticky ? 'announcement-sale-bar__content--sticky' : ''
        }`}
      >
        {haveData && (
          <>
            <span className="announcement__domain">{domain}</span>
            <p
              className="announcement__sale-content"
              dangerouslySetInnerHTML={{ __html: saleContent }}
            ></p>
            <p className="announcement__end-sale">
              Ends
              {listTime.length > 0 &&
                listTime.map((time, idx) => (
                  <span key={idx} className="announcement__time">
                    {time}
                  </span>
                ))}
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .announcement-sale-bar {
    position: relative;
    min-height: 48px;
    pointer-events: none;

    @media screen and (max-width: 768px) {
      min-height: 40px;
    }

    &--hide {
      display: none;
    }
  }
  .announcement-sale-bar__content {
    z-index: 40;
    max-width: 952px;
    background-color: #95be61;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 4px 4px 15px rgba(35, 51, 81, 0.1);

    @media screen and (max-width: 768px) {
      font-size: 13px;
      border-radius: unset;
    }

    .announcement {
      &__line {
        color: var(--text);
        text-align: justify;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &__sale-content {
        font-weight: 600;
        color: var(--primary-invert);
        margin-left: 24px;
        font-size: 24px;
        display: flex;
        text-transform: uppercase;
        position: absolute;

        &--checkout {
          font-size: 15px;
          font-weight: 400;
          margin-left: 8px;
          text-transform: none;
        }

        @media screen and (max-width: 768px) {
          margin-left: 15px;
          position: static;
          font-size: 24px;
          letter-spacing: -0.5px;
          &--checkout {
            font-size: 13px;
            margin-left: 4px;
          }
        }
        @media screen and (max-width: 400px) {
          font-size: 22px;
          letter-spacing: -1px;
          &--checkout {
            font-size: 12px;
          }
        }
        @media screen and (max-width: 370px) {
          font-size: 16px;
          letter-spacing: -1px;
        }
      }

      &__end-sale {
        margin-left: auto;
        margin-right: 16px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
        @media screen and (max-width: 400px) {
          font-size: 13px;
        }
      }

      &__time {
        background: #618a2c;
        border-radius: 4px;
        width: 32px;
        height: 32px;
        margin: 8px 0 8px 6px;
        color: var(--primary-invert);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        &:first-child {
          margin-left: 8px;
        }

        @media screen and (max-width: 768px) {
          width: 28px;
          height: 28px;
          font-size: 15px;
        }
      }

      &__domain {
        margin-left: 15px;
        color: var(--primary-invert);
        font-size: 14px;
        @media screen and (max-width: 964px) {
          display: none;
        }
      }
    }

    &--sticky {
      position: fixed !important;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      border-radius: 0 0 4px 4px;
    }
  }
`

export default SaleAnnouncement
